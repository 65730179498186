$(document).ready(function() {

    // error messages
    var messagesContainer = $('.validateFormGeneralErrors');
    var form_label_required = $(messagesContainer).attr('data-required');
    var form_label_remote = $(messagesContainer).attr('data-remote');
    var form_label_email = $(messagesContainer).attr('data-email');
    var form_label_email_format = $(messagesContainer).attr('data-email-format');
    var form_label_url = $(messagesContainer).attr('data-url');
    var form_label_date = $(messagesContainer).attr('data-date');
    var form_label_number = $(messagesContainer).attr('data-number');
    var form_label_digits = $(messagesContainer).attr('data-digits');

    // custom validation rule
    jQuery.validator.addMethod("emailordomain", function (value, element) {
    return this.optional(element) || /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(value) || /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/.test(value);
    }, form_label_email_format);

    $(".validateFormGeneral").each(function() {

        var optInRequiredMessage = $(this).find('input[data-optin-error]').attr('data-optin-error');

        // rules for validation
        $(this).validate({
            rules: {
                email: {
                    emailordomain: true
                }
            },
            messages: {
                Opt_In: optInRequiredMessage
            },
            errorPlacement: function(label, elem) {
                $(elem).closest(".pardot-field-wrap").append(label);
            },
            highlight: function(element, errorClass, validClass) {
                $(element).parents('.pardot-field-wrap').addClass(errorClass).removeClass(validClass);
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).parents('.pardot-field-wrap').removeClass(errorClass).addClass(validClass);
            }
        });
    });

    // replaces error messages
    jQuery.extend(jQuery.validator.messages, {
        required: form_label_required,
        remote: form_label_remote,
        email: form_label_email,
        url: form_label_url,
        date: form_label_date,
        number: form_label_number,
        digits: form_label_digits
    });

    // opt-in conditions

    $('input[type="checkbox"][name="Opt_In"]').change(function() {

        var checked = $(this).is(':checked')
        var checkedParent = $(this).parent();

        if (checked == true) {
            $(checkedParent).find('.optInDate').attr('name', 'Opt_In_Date');
            $(checkedParent).find('.optInLocation').attr('name', 'Opt_In_Location');
        } else {
            $(checkedParent).find('.optInDate').removeAttr('name');
            $(checkedParent).find('.optInLocation').removeAttr('name');
        }

    });

});